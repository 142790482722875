<template>
    <div class="section">
        <adhesion-contract v-if="showContract === 'adhesioncontract'"></adhesion-contract>
        <p class="subtitle start">TÉRMINOS Y CONDICIONES GENERALES</p>

        <ol>
            <div>
                <p class="subtitle">I. Definiciones.</p>

                <li><p>Las siguientes expresiones tendrán en los presentes Términos y Condiciones el siguiente significado que se les asigna en cada caso, a menos que el contexto requiera otra interpretación:</p>
                    <p>- “PRESTER”, refiere a CORVALÁN 1160 S.A., sociedad incorporada bajo las leyes de la Argentina, con CUIT 30-71005426-2; con domicilio en Maipú 255, Piso 5, Ciudad Autónoma de Buenos Aires, Argentina.</p>
                    <p>- “PRESTER” es una marca registrada y denominación de una aplicación móvil y una página web (o sitio), y un sistema informático de propiedad exclusiva de CORVALÁN 1160 S.A.</p>
                    <p>- “Usuario” y/o “Usted” hacen referencia a vuestra persona, en tanto utilice el sitio y la aplicación PRESTER, solicite asistencia de dinero de “PRESTER” y/o la reciba (“Préstamo”) y/o utilice cualquiera de los servicios brindados a través del sitio y la “aplicación PRESTER” (los “Servicios”).</p>
                </li>
            </div>

            <div>
                <p class="subtitle">II. Aspectos generales. Acceso al sitio y aplicación PRESTER.</p>

                <li><p>El presente documento establece los términos y condiciones generales (los “Términos y Condiciones Generales”) que rigen (i) el acceso al sitio “www.prester.com.ar” y la aplicación móvil “PRESTER” (en adelante “el sitio” y la “aplicación PRESTER”) y (ii) la utilización de cualquiera de los productos o servicios (incluyendo Préstamos y Financiaciones) disponibles o accesibles a través del sitio y la aplicación PRESTER ofrecidos por PRESTER (los “Servicios”).</p></li>

                <li><p>El Usuario reconoce que antes de aceptar los Términos y Condiciones ha tomado pleno conocimiento de los mismos, ha podido acceder a los mismos al ingresar al sitio web como así también pudo leerlos y/o descargarlos durante el proceso de solicitud por los canales detallados previamente, cuenta con copia de ellos a su disposición, pudiendo guardarlos en su ordenador, imprimirlos o retirar copia firmada por PRESTER en el domicilio especial fijado por la misma.</p></li>

                <li><p>El Usuario reconoce y acepta que los presentes Términos y Condiciones se convienen sobre la base del art. 1106 del Código Civil y Comercial de la Nación, la buena fe contractual y el deber de colaboración mutua de las partes.</p></li>

                <li><p>El Usuario reconoce y acepta que en su relación contractual con PRESTER, a través del sitio y la aplicación PRESTER es posible por la implementación de tecnologías de comunicación que permiten brindar los servicios del sitio y la aplicación PRESTER y por lo tanto reconoce y acepta de manera expresa la utilización del medio electrónico, en detrimento del soporte papel, para la comunicación de informaciones, términos y condiciones generales, y otros del presente contrato que se suscribe.</p></li>

                <li><p>El Usuario declara contar con un soporte electrónico y/o tecnología adecuada y necesaria para realizar las operaciones aquí descriptas por intermedio del sitio y aplicación PRESTER.</p></li>

                <li><p>Quien accede al sitio y aplicación PRESTER y desea registrarse en ellos para utilizar sus Servicios debe aceptar expresamente todas las cláusulas establecidas en estos Términos y Condiciones Generales como condición para el ingreso y utilización del sitio y la aplicación PRESTER.</p></li>

                <li><p>En caso que el Usuario no acepte estos Términos y Condiciones, no podrá utilizar los Servicios que en el sitio y aplicación PRESTER se ofrecen.</p></li>

                <li><p>El acceso al sitio y la aplicación PRESTER sólo está disponible para las personas humanas que (i) sean mayores de dieciocho (18) años, y (ii) tengan capacidad legal para contratar. Por ello no podrán operar en el sitio y la aplicación PRESTER las personas jurídicas, los menores de edad o las personas temporal o definitivamente inhabilitadas para contratar.</p></li>

                <li><p>PRESTER se reserva el derecho de modificar los presentes Términos y Condiciones de acuerdo a sus necesidades y las condiciones del mercado. Dichas modificaciones estarán disponibles en cada caso en el sitio y la aplicación PRESTER y entrarán en vigencia a partir de que sean publicadas en el sitio y la aplicación PRESTER o notificadas al Usuario por cualquier medio, lo que ocurra primero.</p></li>

                <li><p>PRESTER podrá continuar, discontinuar o cambiar cualquier producto o servicio ofrecido en el sitio y la aplicación PRESTER cuando lo considere necesario. Asimismo, PRESTER se reserva el derecho de cambiar, mejorar o corregir la información, los contenidos y las descripciones y explicaciones en el sitio y aplicación PRESTER, y suspender y/o negar el acceso al sitio y aplicación PRESTER por razones de mantenimiento (programado o no programado). En caso que un Préstamo o servicio esté concertado y las modificaciones incidan relevantemente en las condiciones en que el mismo fue otorgado, éstas serán informadas al Usuario a la mayor brevedad posible por e-mail y se concretarán de acuerdo a las disposiciones que le sean aplicables. En caso que el Usuario no acepte las modificaciones, deberá comunicar fehacientemente a PRESTER dentro de los diez (10) días corridos de haber sido notificado y podrá dar por rescindido el o los Préstamos y/o Servicios otorgados por medio del sitio y aplicación PRESTER. A tales efectos, el Usuario tendrá un máximo de quince (15) días corridos para pre-cancelar el saldo adeudado, a partir del momento en que se comunique la rescisión.</p></li>
            </div>

            <div>
                <p class="subtitle">III. Relación jurídica entre las Partes.</p>

                <li><p>Las partes acuerdan que conforme a los artículos 286, 288, 1105, 1106 y concordantes del Código Civil y Comercial de la Nación, los presentes Términos y Condiciones Generales, y su aceptación por parte del Usuario vía electrónica o informática, son un compromiso válido y legal entre el Usuario y PRESTER, en especial, pero no limitado, en relación a los Préstamos y/o Servicios que el Usuario solicite a PRESTER y/o le sean otorgados o prestados por PRESTER, o por cualquiera de las empresas proveedoras de créditos que intervengan a través del sitio y aplicación PRESTER.</p></li>

                <li><p>El Usuario que desee utilizar el sitio y la aplicación PRESTER deberá completar los datos necesarios para realizar una solicitud u operar con algunos de los o Servicios del sitio y la aplicación PRESTER, estos son como mínimo su DNI, Nombre y Apellido, Domicilio completo, Número de teléfono celular o móvil, y otros que serán requeridos por PRESTER al momento de la solicitud por medio del sitio y la aplicación PRESTER. Dichos datos tendrán el tratamiento especificado en el apartado VII del presente documento.</p></li>

                <li><p>Cuando los usuarios accedan por primera vez a la aplicación o al sitio PRESTER deberán ingresar mediante su cuenta de Facebook, Google, Twitter o bien por otro correo electrónico.</p></li>

                <li><p>PRESTER, a través del sitio y la aplicación PRESTER, verificará la pertenencia del número celular mediante el envío de un PIN vía SMS que luego el usuario deberá ingresar manualmente en el sitio o la aplicación, o que podrá ser detectado automáticamente por ésta última, siempre que la funcionalidad de los sistemas de comunicación así se lo permitan, a fin de que éste proceda a validar su aparato y línea. Seguidamente, tanto el sitio como la aplicación PRESTER solicitará que el Usuario envíe una fotografía de su rostro (selfie), junto con una foto de su DNI (frente y dorso). Dichas acciones constituyen actos jurídicos válidos y vinculantes para el usuario.</p></li>

                <li><p>Siguiendo el procedimiento indicado en el sitio y la aplicación PRESTER, el Usuario podrá activar su “Cuenta del Usuario”. Una vez activada la Cuenta del Usuario, éste tendrá acceso a la aplicación y/o los servicios del sitio y la aplicación PRESTER mediante el uso del mismo aparato telefónico móvil en el que realizó la registración. En caso de cambiar de aparato deberá bajar nuevamente la aplicación y cargar sus datos actualizados. Cuando el usuario desee utilizar dinero disponible en su cuenta para realizar compras y/o pagos, deberá gestionar una contraseña a través de la aplicación PRESTER en su celular.</p></li>

                <li><p>El Usuario podrá modificar su contraseña en cualquier momento y se obliga a preservar tal contraseña bajo absoluta confidencialidad y, a no revelarla o compartirla con otras personas bajo ningún concepto. A todo evento queda suficientemente establecido que el Usuario es exclusivo responsable por el uso de la contraseña en el sitio y la aplicación.</p></li>

                <li><p>PRESTER y asume las consecuencias derivadas de ello. No obstante ello, el Usuario se obliga a notificar a PRESTER en forma inmediata y por medio fehaciente de cualquier uso no autorizado de su contraseña y/o del ingreso al sitio y la aplicación PRESTER y/o a su Cuenta por terceros no autorizados por él. PRESTER se reserva el derecho a rechazar el Pedido de Activación así como el de cancelar una activación previamente aceptada, en caso de que considere que existan razones suficientes para ellos, así como cuando considere que el Usuario no cumpla con los requisitos mínimos establecidos en la cláusula 6 del presente.</p></li>

                <li><p>La aplicación PRESTER que el Usuario instala en su dispositivo cuenta con cuatro apartados: a) Mis datos personales, donde el Usuario puede consultar los datos personales ingresados por él mismo al momento de solicitar un préstamo y/u otro de los servicios prestados por PRESTER, b) Mi cuenta, donde puede ver los préstamos que solicitó a PRESTER, si estos fueron aprobados o rechazados, su saldo actual, cantidad de cuotas y otra información relevante referida a dichos préstamos y/o cualquier otro servicio prestado por PRESTER, c) Mensajes: Aquí quedan registrados los mensajes enviados por PRESTER comunicando rechazos o aprobaciones de préstamos, avisos de transferencia realizada, avisos de vencimiento de cuota, y otros avisos en relación a los servicios prestados por PRESTER que el Usuario haya solicitado, d) Contacto: Desde aquí el Usuario puede contactar a PRESTER por cualquier consulta, además se muestran todos los medios de contacto.</p></li>

                <li><p>En caso de no poder acceder al apartado MI CUENTA, por pérdida del teléfono móvil vinculado, o por cualquier otra razón, deberá solicitar a PRESTER copia del mismo, por medio de la cuenta de e-mail: consultas@prester.com.ar.</p></li>

                <li><p>En ningún caso la falta de acceso al apartado MI CUENTA podrá ser invocada como causal suficiente para no abonar o demorar el pago del débito adeudado y vencido.</p></li>

                <li><p>La aplicación PRESTER es intransferible y de uso personal. Ante la pérdida del teléfono móvil, el Usuario, deberá dar inmediato aviso a consultas@prester.com.ar o al teléfono +549 11 6350 4807. El Usuario no responderá por el uso por parte de terceros de la Aplicación PRESTER sustraída o extraviada a partir de la hora cero (0) del día siguiente al de la denuncia respectiva, pero responderán ilimitadamente por los usos anteriores. La Aplicación PRESTER es de propiedad de PRESTER siendo el Usuario el depositario de sus Servicios. Estas son para uso de consumos personales y no serán usadas con fines comerciales o financieros, ni luego de su vencimiento o anulación. El Usuario será responsable de la Aplicación PRESTER solicitada, por su uso incorrecto, guarda, conservación, y por todas las consecuencias del uso hasta su vencimiento o anulación, PRESTER quedará facultada para inhabilitar la aplicación PRESTER a nombre del Usuario.</p></li>

                <li><p>El Usuario entiende que el sitio y la aplicación PRESTER es un sistema informático y que, como cualquier creación humana, no está exenta de errores. En este sentido, PRESTER no puede garantizar que el sitio web y la aplicación PRESTER funcionará sin errores (bugs).</p></li>

                <li><p>PRESTER no garantiza el acceso, uso continuado o ininterrumpido del sitio y la aplicación PRESTER. El Usuario entiende que el sitio web y la aplicación PRESTER puede no estar disponible debido a dificultades técnicas o fallas de Internet o por cualquier otra circunstancia, incluso ajena a la responsabilidad de PRESTER.</p></li>

                <li><p>En ningún caso PRESTER será responsable por las demoras y/o problemas de cualquier especie que surjan en los medios o mecanismos de cobro y/o desembolso de los préstamos, utilizados en relación a los mismos.</p></li>

                <li><p>No obstante lo dispuesto precedentemente, PRESTER se compromete a realizar sus mejores esfuerzos para procurar restablecer el sistema con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad.</p></li>

                <li><p>El Usuario garantizará y responderá en cualquier caso por la veracidad, exactitud, vigencia y autenticidad de los datos facilitados y se comprometerá a mantenerlos debidamente actualizados.</p></li>

                <li><p>El Usuario autoriza a PRESTER a confirmar los datos suministrados acudiendo a entidades públicas, compañías especializadas o centrales de riesgo, como así también evaluar atributos de comportamiento en función del uso del celular en el que se encuentre instalada la aplicación. La información que PRESTER obtenga de estas consultas, así como la proporcionada por el usuario, será tratada en forma confidencial y empleada exclusivamente para evaluar la solicitud de crédito recibida y no será de ninguna forma compartida con otras empresas o personas físicas ajenas a PRESTER. Dichos datos tendrán el tratamiento especificado en el apartado VII del presente documento.</p></li>

                <li><p>Expresamente el Usuario presta conformidad para que PRESTER pueda informar del otorgamiento de cada Préstamo a las bases de datos de las distintas centrales de riesgo crediticio públicas y privadas, y con posterioridad a ello, acerca del cumplimiento o eventual incumplimiento del usuario respecto de las condiciones de pago convenidas.</p></li>

                <li><p>PRESTER podrá solicitar al Usuario la presentación o envío de cualquier comprobante y/o dato adicional que considere necesario a efectos de confirmar y/o verificar sus datos personales.</p></li>
            </div>

            <div>
                <p class="subtitle">IV. Solicitud de Servicios de Crédito.</p>

                <li><p>El Usuario, luego de ingresar a su Cuenta en el sitio y la aplicación PRESTER, podrá solicitar a PRESTER, en todo momento, el otorgamiento de una asistencia en dinero (el “Préstamo”) indicando (i) el monto de la misma, y (ii) el plazo de devolución que propone, y (iii) la cuenta bancaria identificada por su CBU (clave bancaria única) en la que se acreditará y debitará el otorgamiento y devolución del Préstamo respectivamente (la “Solicitud”). En caso de no poseer una cuenta, PRESTER podrá considerar habilitar otros canales para el desembolso y pago del Préstamo, como sucursales PRESTER o bien bocas de terceros habilitados a tal fin.</p></li>

                <li><p>Una vez recibida la Solicitud, en un plazo no mayor a 5 días hábiles, PRESTER comunicará la decisión de rechazo o aprobación al Usuario. En el caso de la aplicación móvil informará mediante notificación push a la app que el usuario instaló o bien mediante correo electrónico: (i) el monto del Préstamo susceptible de ser acordado, y (ii) el monto y las fechas de vencimiento de las cuotas del Préstamo, y (iii) los términos y condiciones del crédito para ser aceptados por el cliente. Para el caso del sitio web, informará mediante notificación vía correo electrónico: (i) el monto del Préstamo susceptible de ser acordado, y (ii) el monto y las fechas de vencimiento de las cuotas del Préstamo, y (iii) los términos y condiciones del crédito por el cliente.</p></li>

                <li><p>PRESTER se reserva el derecho de rechazar la Solicitud de Préstamo efectuada por un Usuario cuando esté presente antecedentes crediticios desfavorables y/o no posea ingresos de acuerdo al monto de cuota que deba abonar, contemplando que esta no supere el 30% de su ingreso, de forma de no afectar su capacidad de pago respecto de sus gastos habituales.</p></li>

                <li><p>El Usuario, una vez recibida la Propuesta de Préstamo, comunicará a PRESTER, en el sitio y la aplicación, la aceptación de la Propuesta de Préstamo (la “Aceptación”).</p></li>

                <li><p>La Aceptación obligará a PRESTER a liquidar en un plazo no mayor a 5 días hábiles el Préstamo en las condiciones detalladas en la Propuesta de Préstamo.</p></li>

                <li><p>PRESTER enviará a un correo electrónico verificado del cliente copia de estos términos y condiciones junto con el préstamo liquidado, cantidad y montos de cuota a abonar junto con las fechas de vencimiento.</p></li>

                <li><p>El Usuario entiende y reconoce que la Solicitud y la Aceptación, implica la aceptación de los presentes Términos y Condiciones.</p></li>

                <li><p>PRESTER, en todo momento, cumplirá con las normas fiscales vigentes en la República Argentina a cuyos fines procederá a emitir las facturas que correspondan.</p></li>

                <li><p>El Usuario podrá revocar la aceptación de la Propuesta del Préstamo y/o cualquier otro servicio que se le haya otorgado desde el sitio y la aplicación PRESTER dentro de los diez (10) hábiles bancarios computados a partir de la aceptación de la misma. Para efectuar su derecho de revocación, el Usuario deberá notificar a PRESTER su decisión por medio del botón de arrepentimiento de la página web www.prester.com.ar o escribiendo un mail a reclamos@prester.com.ar, o mediante la devolución del dinero del Préstamo y/o del servicio solicitado dentro del plazo de diez (10) días hábiles bancarios; en cuyo caso se deberán los intereses y gastos que correspondieran.</p></li>

                <li><p>El sitio y la aplicación PRESTER podrán ofrecer la financiación de productos y servicios, incluyendo pero no limitándose a los siguientes: motos, bicicletas, materiales para la construcción y/o mejora del hogar, electrodomésticos, viajes. Esta operatoria se realizará en acuerdo con empresas dedicadas y especializadas en dicho rubro (partners), que ofrecerán la financiación de PRESTER como una de las formas de acceder a dichos productos o servicios. En estos casos el cliente podrá realizar dichas operaciones tanto de manera presencial en alguno de dichos comercios como de forma remota a través de los sitios de comercio electrónico de los partners. Los partners podrán utilizar folletería y/o campañas en medios digitales para promover estas alianzas comerciales. El cliente en estos casos realizará una solicitud de crédito al igual que si solicitara un crédito en efectivo, con la diferencia que el monto de la financiación será transferido al Comercio que realiza la venta financiada. El cliente autoriza e instruye a PRESTER a realizar dicha acreditación previa verificación de PRESTER de la entrega del producto o contrato de servicio al cliente. Dicha entrega quedará confirmada con la firma del cliente en la factura o remito correspondiente. La forma de pago de cuotas de estos créditos es la misma que para los préstamos con acreditación en cuenta del cliente.</p></li>

                <li><p>PRESTER podrá contratar servicios de proveedores de comercialización de préstamos, quienes tendrán la tarea de realizar convenios con comercios que participen del sistema de créditos.</p></li>

                <li><p>PRESTER contrata servicios de comercializadores quienes se ocuparán de reunir documentación y verificar el disponible en el caso de préstamos a empleados del sector público.</p></li>

                <li><p>PRESTER no cobra ningún cargo por adelantado ni autoriza al cobro de ningún cargo por la gestión de un préstamo previo a su otorgamiento. Las comisiones se abonan al momento del desembolso, pudiendo descontarse del capital o bien capitalizarse y luego ser abonadas a lo largo del préstamo.</p></li>

                <li><p>En caso de duda de algún consumidor respecto de si un comercializador está autorizado por PRESTER podrán canalizar todas sus consultas al mail o al celular/whatsapp que figura en medios de contacto.</p></li>
            </div>

            <div>
                <p class="subtitle">V. Precio de los Servicios.</p>

                <li><p>El Usuario al comunicar la Aceptación de la Propuesta de Préstamo, siempre que el Préstamo sea acreditado en su cuenta bancaria, reconoce y acepta que deberá a PRESTER o la suma que éste indique en la Propuesta de Préstamo, a la que se le deben adicionar los intereses (antes de IVA) detallados a continuación:</p>
                    <p>Intereses compensatorios: Los intereses se calcularán mediante una Tasa Nominal Anual (TNA) sin IVA Mínima de {{ rates.minTNAWithoutIva }}% y Máxima de {{ rates.maxTNAWithoutIva }}%.</p>
                    <p>El TEA mínimo es de {{ rates.minTEA }}%, TEA máximo {{ rates.maxTEA }}%. El CFTNA con IVA mínimo es de {{ rates.minCFTNAWithIva }}% y el máximo es de {{ rates.maxCFTNAWithIva }}%. El CFTEA con IVA mínimo es de {{ rates.minCFTEAWithIva }}% y el máximo es de {{ rates.maxCFTEAWithIva }}%.</p>
                    <p>No se cobran otros cargos o comisiones. La tasa de interés varía en función de los antecedentes crediticios del solicitante con PRESTER y/o con el mercado de créditos y su capacidad de repago estimada. La TNA, TEA y el CFT se informan durante el proceso de solicitud por el sitio y la app, antes de su otorgamiento, debiendo el cliente aceptar estos términos y condiciones como así también los intereses, comisiones y gastos a abonar. El sistema de amortización utilizado es el sistema francés en cuotas iguales. Plazo mínimo {{ rates.minPeriod }} meses y máximo {{ rates.maxPeriod }} meses. En el caso de préstamos otorgados por descuento de haberes, de existir gastos de comercialización, estos se informarán al cliente al momento de la aceptación y previo al desembolso, debiendo el cliente dar su conformidad.</p>
                </li>

                <li><p>Ejemplo: Monto solicitado de ${{ rates.exampleRequestedAmount }} a {{ rates.examplePaymentPeriodAmount }} meses | TNA (sin IVA): {{ rates.exampleTNAWithoutIVA }}% – TEA (sin IVA): {{ rates.exampleTEAWithoutIVA }}% – CFTNA (con IVA): {{ rates.exampleCFTNAWithIVA }}% - CFTEA (con IVA): {{ rates.exampleCFTEAWithIVA }}% | Cuota: ${{ rates.exampleInstallmentAmount }} | Total a pagar: ${{ rates.exampleTotalAmountToPay }}.</p></li>

                <li><p>PRESTER podrá aceptar cancelaciones anticipadas parciales o totales de los Préstamos, las cuales se podrán efectuar en cualquier fecha, debiendo abonarse en tal caso las cuotas devengadas hasta el momento más el capital restante. No se cobrarán cargos o comisiones por cancelación.</p></li>

                <li><p>La mora en el cumplimiento de las obligaciones asumidas mediante la presente, se producirá de pleno derecho, e implicará la caducidad automática de todo plazo que se encontrará pendiente con lo que resultará exigible el saldo total lo adeudado, con más los correspondientes intereses compensatorios, moratorios y punitorios pactados, y los gastos de gestión relacionados a la mora. En caso de mora, el importe adeudado a dicho momento devengará además estos conceptos:</p>
                    <p>a) Intereses punitorios: Estos serán equivalentes al {{ rates.punitiveInterest }}% de los intereses compensatorios pactados en la Solicitud aceptada.</p>
                    <p>b) Gastos de gestión de mora: En caso de atraso o mora en el pago por parte del cliente por un período superior a 90 días PRESTER deberá contratar servicios de terceros para el recupero de la deuda. Dichos costos serán trasladados al cliente y representan un 20% de la deuda reclamada. PRESTER gestionará mediante recursos propios la mora temprana (hasta 90 días) en forma telefónica, por whatsapp, mail, SMS, cartas, y otros medios. Dichos gastos no se cargarán al cliente. Al alcanzar los 180 días de mora, su caso será derivado a un Estudio Jurídico, debiendo el cliente afrontar los gastos y honorarios judiciales o extrajudiciales que se devenguen.</p>
                </li>

                <li><p>PRESTER en calidad de Tomador podrá contratar, a su cargo, un seguro de vida que cubrirá el fallecimiento o la incapacidad total y permanente como consecuencia de enfermedad o accidente, por una suma asegurada actualizable equivalente al saldo de las deudas que mantenga con PRESTER por cualquier concepto, al momento del siniestro, en las condiciones y hasta la suma máxima que se establezca en la póliza respectiva. Además PRESTER podrá brindar el servicio a los Usuarios que así lo soliciten de subcontratación de seguros de vida, protección de bolso y/o celular, seguro del hogar, seguros de ahorro y retiro, seguro de saldo deudor y/o cualquier otro seguro que en el futuro PRESTER decida a su sólo criterio ofrecer a los Usuarios, en alguna compañía del mercado o también podrá cederlos a una entidad financiera que luego subcontrate a una compañía de seguros. Estos productos serán ofrecidos a los Usuarios que así lo soliciten, con su consentimiento previo.</p></li>

                <li><p>Todos los impuestos actuales o futuros que gravaren esta operación están a cargo del Usuario.</p></li>

                <li><p>PRESTER / CORVALÁN 1160 S.A. podrá ceder en cualquier momento los créditos actuales o futuros que tuviere en contra del Usuario Titular, derivados de la utilización del sitio web o la App PRESTER, sin necesidad de notificación por acto público, en los términos del Art. 72 de la ley 24.441 a los efectos de su validez por ante terceros y/o deudor cedido.</p></li>
            </div>

            <div>
                <p class="subtitle">VI. Pago de las cuotas del préstamo.</p>

                <li><p>El Usuario, en este acto, mediante la aceptación de los Términos y Condiciones, se compromete al pago de la totalidad de las cuotas del préstamo.</p></li>

                <li><p>El Usuario abonará en las fechas pautadas y por los montos confirmados por él mismo en la aplicación al aceptar la propuesta de préstamo los siguientes conceptos: el capital,, los intereses compensatorios e IVA, así como también, de producirse, los siguientes: intereses punitorios y gastos de gestión de cobranzas.</p></li>

                <li><p>El pago de las cuotas se podrá cursar, entre otros, a través de los siguientes medios:</p>
                    <p>a) mediante una tarjeta de débito del usuario, para lo cual CORVALÁN 1160 S.A. podrá contratar un servicio de terceros que gestione dichas suscripciones a débito automático como por ejemplo MOBBEX y/u otra empresa similar,</p>
                    <p>b) descuento de haberes en caso de convenio entre su empleador y/o mutual y/o cooperativa con PRESTER,</p>
                    <p>c) el Usuario expresamente autoriza a CORVALÁN 1160 S.A. y/o Épico S.A. y/o Major Contractor Group S.A. y/o Mutual 20 de Septiembre y/o M&G Negocios S.A.S. y/o Cooperativa Creditan y/o cualquier otra empresa que CORVALÁN 1160 S.A. autorice, a percibir el capital, los intereses moratorios y la penalidad por mora del mutuo mediante la operatoria de débito interno o intrabancario y/o DEBIN Spot, descontando tales importes de cualquier cuenta bancaria de su titularidad,</p>
                    <p>d) en cualquier boca de atención de Pago Fácil indicando su número de DNI e indicando que desea abonar a PRESTER,</p>
                    <p>e) mediante el servicio pagomiscuentas,</p>
                    <p>f) a través de transferencia bancaria a una cuenta de CORVALÁN 1160 S.A.,</p>
                    <p>g) en el domicilio de pago establecido al final de la presente,</p>
                    <p>h) algún otro medio disponible.</p>
                    <p>La cancelación de los montos adeudados por el Usuario a CORVALÁN 1160 S.A. será efectuada al momento en que CORVALÁN 1160 S.A. perciba la totalidad de los importes adeudados.</p>
                </li>

                <li><p>El Usuario se compromete a notificar a PRESTER ante un cambio del CBU con el que opera habitualmente. Dicha notificación deberá ser de manera fehaciente y con la suficiente antelación a la fecha correspondiente al próximo vencimiento.</p></li>

                <li><p>En caso de que, en cualquier momento durante la vigencia del Préstamo, por cualquier circunstancia, el Usuario incumpliere con el pago íntegro de cualesquiera de las Cuotas en los términos convenidos y/o abonare una o varias Cuotas sólo parcialmente, PRESTER imputará las cantidades parciales recibidas según el siguiente orden de prelación: (i) intereses punitorios e IVA, ii) gastos de gestión de cobranzas e IVA, iii) intereses compensatorios e IVA, iv) gastos judiciales, v) capital.</p></li>

                <li><p>PRESTER podrá, a su solo criterio, otorgar al Usuario, ante un pedido de éste, una extensión del plazo para el repago del monto adeudado del Préstamo con las formalidades que establezca y previa información y aceptación de las condiciones por parte del Usuario.</p></li>
            </div>

            <div>
                <p class="subtitle">VII. Política de Privacidad y Protección de Datos Personales.</p>

                <li><p>Al prestar conformidad por los presentes Términos y Condiciones, el Usuario también está prestando conformidad a la Política de Privacidad y a todos los derechos y obligaciones contenidos en el sitio y la aplicación PRESTER. Se considerará que (i) el acceso de cualquier visitante al sitio y la aplicación PRESTER, en forma ocasional o frecuente, o (ii) la utilización de alguno de los servicios prestados por el sitio y la aplicación PRESTER, o (iii) su registro en el sitio y la aplicación PRESTER, implica la aceptación de estos Términos y Condiciones Generales y la Política de Privacidad y tienen carácter obligatorio y vinculante para todos los Usuarios.</p></li>

                <li><p>El Usuario declara y manifiesta con relación a sus datos personales, es decir, toda información suministrada a PRESTER y recabada por ésta, vinculada al servicio objeto de la presente (los “Datos Personales”), que presta consentimiento para que: (i) los mismos sean utilizados para la consideración de cualquier préstamo y/o servicio que pueda solicitar a PRESTER y para cualquier ofrecimiento que PRESTER pueda efectuarle en el futuro; (ii) PRESTER pueda informar la situación de pago a las agencias de informes crediticios y al Banco Central de la República Argentina en los términos del Art. 26 de la Ley 25.326 y su reglamentación, (iii) todos los Datos, o información de cualquier tipo, que entregará a PRESTER pueda ser procesada y/o transferida a sociedades vinculadas, controlantes, controladas y/o afiliadas a PRESTER.</p></li>

                <li><p>Los Datos solicitados en la aplicación y/o el sitio de PRESTER resultan indispensables para la evaluación de los préstamos, por lo cual deben ser obligatoriamente aportados por el Usuario en forma exacta, veraz y actualizada.</p></li>

                <li><p>El Usuario, titular de los datos personales, autoriza a PRESTER para la conservación, procesamiento y transferencia de los Datos cuando éstos sean requeridos por otras entidades financieras, organismos públicos y/o autoridades judiciales; y en los casos en los que lo autorice la Ley Nacional de Protección de Datos Personales.</p></li>

                <li><p>Asimismo, el Usuario toma conocimiento y autoriza a PRESTER expresamente a recabar todos los datos crediticios y/o bancarios del Usuario de bases de datos públicas, privadas y/o de empresas especializadas (a saber: nº de cuenta/s, tipo de cuenta/s y banco/s donde esté/n abierta/s la/s misma/s, entre otros) que considere necesarios para analizar o efectivizar el otorgamiento o el cobro de un préstamo.</p></li>

                <li><p>En cumplimiento con la Ley Nacional de Protección de Datos personales y la restante normativa vigente, PRESTER brindará atención a las consultas y pedidos de acceso a la información (artículo 14 de la Ley 25.326). Además, el derecho a la rectificación, actualización o supresión de los datos personales inexactos o incompletos podrá ejercerse sin cargo alguno para el interesado (artículo 19 de la Ley 25.326). El derecho de acceso solo puede ser ejercido en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto (inc. 3 artículo 14 de la Ley 25.326). Asimismo, de acuerdo con lo establecido en el artículo 26 de la Ley 25.326, solo se podrán archivar, registrar o ceder los datos personales que sean significativos para evaluar la solvencia económico-financiera de los afectados durante los últimos cinco años. Dicho plazo se reducirá a dos años cuando el deudor cancele o de otro modo extinga la obligación, debiéndose hacer constar dicho hecho.</p></li>
            </div>

            <div>
                <p class="subtitle">VIII. Cláusulas adicionales.</p>

                <li><p>El Usuario en oportunidad del uso del sitio y la aplicación PRESTER se obliga a no ejecutar y/o realizar operaciones y/o actos que pongan en riesgo, o generen daños inmediatos o mediatos, a la operación del sitio y la aplicación PRESTER. En tal evento PRESTER y/o el sitio y la aplicación PRESTER podrán suspender temporal o definitivamente al Usuario y/o cancelar su Cuenta, sin perjuicio del cumplimiento de las obligaciones contraídas y de las acciones legales que pudieran corresponder, particularmente en relación a los daños causados.</p></li>

                <li><p>PRESTER y/o el sitio y la aplicación PRESTER, en un fiel reflejo de su responsabilidad empresaria, informa al Usuario que el sitio y la aplicación PRESTER es de acceso voluntario por él, sujeto a los Términos y Condiciones y la Política de Privacidad, para el uso y/o goce de los servicios, información o entretenimientos que el sitio y la aplicación PRESTER contiene y brinda. En consecuencia, PRESTER y/o el sitio y la aplicación PRESTER informan al Usuario que no asumen ninguna responsabilidad adicional a las que se fijan específicamente en los Términos y Condiciones Generales y la Política de Privacidad.</p></li>

                <li><p>PRESTER y/o el sitio y la aplicación PRESTER dejan claramente establecido que no asumen ningún tipo de responsabilidad por la publicidad de terceros que contenga el sitio y la aplicación PRESTER ni serán responsables por las vinculaciones que el Usuario establezca con ellos y/o con otros Usuarios ni serán responsables por las eventuales consecuencias, de cualquier tipo que fuera, resultantes de fallas en el sistema, en el servidor o en Internet que pudieran surgir del acceso y/o uso del sitio y la aplicación PRESTER bajo cualquier modalidad que el usuario empleara ni serán responsables por eventuales errores u omisiones contenidos en el sitio y la aplicación PRESTER.</p></li>

                <li><p>Las aplicaciones, servicios y contenidos que provee el sitio y la aplicación PRESTER fueron diseñados para el uso por parte de los Usuarios. Los programas, bases de datos, redes y archivos que permiten al Usuario acceder y usar su Cuenta son de propiedad de PRESTER y están protegidos por las leyes y tratados internacionales de derecho de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan prohibidos, salvo autorización expresa y por escrito de PRESTER.</p></li>

                <li><p>PRESTER con el consentimiento previo del Usuario a través del sitio o la aplicación PRESTER podrá utilizar los datos aportados por el Usuario para enviar información y/o promociones y/o publicidad asociada a través del sitio y la aplicación PRESTER, correo electrónico, teléfono móvil, o bien por nota enviada por correo al domicilio indicado en la Solicitud de Préstamo. El Usuario podrá solicitar el no envío de material promocional o publicitario en todo momento y bajo cualquiera de las formas mencionadas precedentemente.</p></li>

                <li><p>La obtención de una Cuenta en el sitio y la aplicación PRESTER por el Usuario es gratuita y constituye una condición de procedencia ineludible para el uso y/o goce de las aplicaciones y/o servicios que provee el sitio y la aplicación PRESTER.</p></li>

                <li><p>El Usuario se compromete a no: (1) transmitir mensajes anónimos o bajo un seudónimo; (2) transmitir mensajes o información injuriosa, intimidatoria, engañosa, difamatoria, obscena, o que viole el derecho a la intimidad o la propiedad intelectual o industrial de terceros, o que pueda resultar perjudicial para éstos o para el Titular; (3) transmitir ningún archivo que contenga virus o cualquier otro elemento contaminante o destructivo para el Sitio como pueden ser un robot, araña (spider), gusano, troyano, u otros aparatos automáticos o procesos manuales para monitorear o copiar las páginas del Sitio o aplicación PRESTER o la información contenida; (4) transmitir publicidad, materiales promocionales, u ofertas excepto en aquellas áreas (Ej. Cartelera de Avisos clasificados, Carteleras de Mensajes) destinadas a ese fin; (5) enviar e-mails no solicitados, en forma masiva, a otros Usuarios; (6) intentar tener acceso no autorizado, utilizar el Sitio o aplicación PRESTER en alguna manera que pudiera dañar, inhabilitar, sobrecargar o deteriorar su contenido o funcionalidad o efectuar cualquier otro acto reñido con la finalidad del Sitio o aplicación PRESTER o con sus Términos y Condiciones.</p></li>

                <li><p> A todos los efectos emergentes de la Solicitud y del Préstamo, el Usuario constituye domicilio en aquel que declaró al momento de la primera Solicitud, obligándose a notificar cualquier cambio a través de consultas@prester.com.ar.</p></li>

                <li><p>Además de los servicios directos ofrecidos por PRESTER a clientes finales, PRESTER ofrece sus servicios de desarrollo de plataformas tecnológicas aplicadas al otorgamiento de préstamos y/o tarjetas de crédito y/o cuentas a otras entidades. En estos casos las operaciones que se realicen serán entre el cliente final y la empresa a la que PRESTER presta servicios, por lo que PRESTER no posee responsabilidad sobre dicha operatoria. Sin perjuicio de ello, PRESTER se compromete a supervisar que la empresa proveedora de créditos que utilice los servicios de PRESTER, preste sus servicios adecuadamente.</p></li>
            </div>

            <div>
                <p class="subtitle">IX. Caso Especial. Autorización de Descuento.</p>

                <li><p>Las cláusulas de la presente sección IX son aplicables a Usuarios que resulten empleados afiliados o afiliables a una entidad Mutual y/o Sindicato y/o Cooperativa que posea convenio de descuento de haberes con empleadores públicos o privados, y que a su vez posean convenio de cooperación con PRESTER para la generación, administración o gestión de créditos otorgados por PRESTER.</p></li>

                <li><p>El Usuario autoriza de forma expresa a PRESTER y/o a la entidad a gestionar y percibir descuentos de haberes para ser aplicados al cobro de las cuotas correspondientes al préstamo. Para dicha gestión, PRESTER emitirá un listado y lo hará llegar a la entidad y/o al empleador del Usuario. Dichos cobros serán percibidos por PRESTER y/o quien ésta designe.</p></li>

                <li><p>En caso de empleados públicos, estos autorizan a canalizar los descuentos correspondientes mediante el Código de Descuento en Tesorería de la Municipalidad, Provincia, Nación y/o Institución Titular de los mismos que PRESTER estime pertinente.</p></li>

                <li><p>A los efectos de descuentos sobre los haberes del Usuario, éste último hace expresa cesión a favor de PRESTER, y hasta tanto el Usuario indique lo contrario en forma fehaciente, previa conformidad de PRESTER respecto del pago de toda deuda mantenida con esta última.</p></li>
            </div>

            <div>
                <p class="subtitle">X. Notificaciones. Domicilio PRESTER.</p>

                <li><p>El Usuario acepta la plena validez de las notificaciones enviadas a PRESTER y recibidas de PRESTER, a través del sitio y la aplicación PRESTER y/o por correo electrónico (en la dirección comunicada por el Usuario), destacándose que el presente contrato se conviene sobre la base de la buena fe contractual y el deber de colaboración de las partes.</p></li>

                <li><p>Para todos los efectos de la presente, PRESTER constituye domicilio especial en Maipú 255, Piso 5, Ciudad Autónoma de Buenos Aires, Argentina.</p></li>
            </div>
        </ol>

        <p class="end">Los presentes Términos y Condiciones se actualizaron el {{ rates.lastUpdate }}.</p>
    </div>
</template>

<script>
import AdhesionContract from "@/views/solicitud/legal/AdhesionContract.vue";
export default {
    name: "TermsAndConditions",
    components: {AdhesionContract},
    props: {
        showContract: { type: String, default: ""},
    },
    data: function () {
        return {
            rates: {
                minTNAWithoutIva: '',
                maxTNAWithoutIva: '',
                minTEA: '',
                maxTEA: '',
                minCFTNAWithIva: '',
                maxCFTNAWithIva: '',
                minCFTEAWithIva: '',
                maxCFTEAWithIva: '',
                exampleRequestedAmount: '',
                examplePaymentPeriodAmount: '',
                exampleTotalAmountToPay: '',
                exampleInstallmentAmount: '',
                exampleTNAWithoutIVA: '',
                exampleCFTNAWithIVA: '',
                exampleCFTEAWithIVA: '',
                exampleTEAWithoutIVA: '',
                lastUpdate: '',
                minPeriod: '',
                maxPeriod: '',
                punitiveInterest:''
            },
        }
    },
    created() {
        this.$apiserver.getGeneralRates()
            .then((data) => {
                let rates = data.data;
                this.rates.minTNAWithoutIva = rates.minTNAWithoutIva
                this.rates.maxTNAWithoutIva = rates.maxTNAWithoutIva
                this.rates.minTEA = rates.minTEA
                this.rates.maxTEA = rates.maxTEA
                this.rates.minCFTNAWithIva = rates.minCFTNAWithIva
                this.rates.maxCFTNAWithIva = rates.maxCFTNAWithIva
                this.rates.minCFTEAWithIva = rates.minCFTEAWithIva
                this.rates.maxCFTEAWithIva = rates.maxCFTEAWithIva
                this.rates.exampleRequestedAmount = rates.exampleRequestedAmount
                this.rates.examplePaymentPeriodAmount = rates.examplePaymentPeriodAmount
                this.rates.exampleTotalAmountToPay = rates.exampleTotalAmountToPay
                this.rates.exampleInstallmentAmount = rates.exampleInstallmentAmount
                this.rates.exampleTNAWithoutIVA = rates.exampleTNAWithoutIVA
                this.rates.exampleCFTNAWithIVA = rates.exampleCFTNAWithIVA
                this.rates.exampleCFTEAWithIVA = rates.exampleCFTEAWithIVA
                this.rates.exampleTEAWithoutIVA = rates.exampleTEAWithoutIVA
                this.rates.lastUpdate = rates.tycLastUpdate
                this.rates.minPeriod = rates.minPaymentPeriodAmount;
                this.rates.maxPeriod = rates.maxPaymentPeriodAmount;
                this.rates.punitiveInterest = rates.punitiveInterest;
            })
            .catch((e) => {
                console.log(e.message);
            })
    },
};
</script>

<style scoped>
p {
    margin: 0;
    color: #000000;
    font-size: 13pt;
    font-family: "CommonsRegular";
    text-align: left;
    text-align: justify;
    padding-bottom: 10px;
}

li::marker {
    font-weight: 500;
}

.section {
    padding: 0 10%;
}

.subtitle {
    color: #000000 !important;
    font-size: 13pt;
    font-family: "CommonsBold";
    text-align: left;
    padding-top: 15px;
    text-indent: -30px;
}

.start {
    padding-top: 50px;
}

.end {
    padding-bottom: 50px;
}
</style>