<template>
  <div>
    <p class="subtitle start">CONTRATO DE ADHESIÓN</p>
    <p class="subtitle center">SOLICITUD DE PRÉSTAMO PERSONAL</p>
    <table>
      <tr>
        <th colspan="4">Nombre y Apellido:</th>
      </tr>
      <tr>
        <th>DNI:</th>
        <th>CUIT / CUIL:</th>
        <th colspan="2">Nacionalidad:</th>
      </tr>
      <tr>
        <th>Fecha de nacimiento:</th>
        <th colspan="3">Dirección de email:</th>
      </tr>
      <tr>
        <th>Sexo:</th>
        <th colspan="3">Estado Civil:</th>
      </tr>
      <tr>
        <th rowspan="2">Domicilio:</th>
        <th>Calle:</th>
        <th>Altura:</th>
        <th>Piso y Depto:</th>
      </tr>
      <tr>
        <th>Localidad:</th>
        <th>CP:</th>
        <th>Provincia:</th>
      </tr>
      <tr>
        <th>Teléfono:</th>
        <th>Celular:</th>
        <th colspan="2">Laboral:</th>
      </tr>
      <tr>
        <th>Rel. laboral:</th>
        <th colspan="3">Nombre de la empresa:</th>
      </tr>
      <tr>
        <th>Domicilio laboral:</th>
        <th colspan="3"></th>
      </tr>
      <tr>
        <th colspan="4">Cargo, Título Profesional u Ocupación:</th>
      </tr>
      <tr>
        <th>Ingreso mensual:</th>
        <th>Otros ingresos:</th>
        <th colspan="2">Destino préstamo:</th>
      </tr>
      <tr>
        <th colspan="4" class="text center">
          Solicitud de Préstamo Personal – Términos y condiciones
        </th>
      </tr>
      <tr>
        <th colspan="4" class="text">
          Solicitó un importe de $ _________ en __ cuotas mensuales y consecutivas de $ _______ (*)<br><br>
          siendo el monto total a reintegrar $ ___________<br><br>
          T.E.M.: _______ % T.N.A.: ______ % T.E.A.: ______% Gastos Administrativos: _____ %<br><br><br>
          % OTROS GASTOS: $____
        </th>
      </tr>
      <tr>
        <th colspan="4" class="text center">
          (*) El préstamo se amortiza en cuotas fijas mensuales, bajo el esquema del sistema francés. Abona también IVA, gastos según detalle en esta solicitud.
        </th>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "AdhesionContract",
  components: {},
  methods: {},
};
</script>

<style scoped>
p {
  color: #000000;
  font-size: 10pt;
  font-family: "CommonsRegular";
  text-align: left;
}

th {
  border: 1px solid #000000;
  border-collapse: collapse;
  height: 30px;
  padding: 3px;
  padding-bottom: 20px;
  font-size: 10pt;
  font-family: "CommonsRegular";
}

table {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 700px;
}

.text {
  font-size: 12pt;
  padding-bottom: 0px;
}

.subtitle {
  color: #000000 !important;
  font-size: 13pt;
  font-family: "CommonsBold";
  text-align: left;
  padding-top: 15px;
  text-indent: -30px;
}

.start {
  padding-top: 50px;
}

.center {
  text-align: center;
}
</style>
